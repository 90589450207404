.layout-primary {
  background-color: var(--primary) !important
}

.layout-secondary {
  background-color: var(--secondary) !important;
}

#logo-slogan {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1
}

#logo-slogan img {
  width: 20vw;
  height: 14vh;
}

#slogan h1 {
  max-width: 50vw;
  font-size: 2vw;
  letter-spacing: -.1px;
  text-align: center;
  color: white;
  margin: 0;
}
