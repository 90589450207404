:root {
    --primary: "#1976d2"; // default value before injection
    --primary-darker: "#561f61";
    --secondary: "#ce93d8"; // default value before injection
}

.link {
    text-decoration: none;
    color: var(--primary);

    &:hover {
        color: var(--secondary);
    }
}

a.MuiButton-root:hover {
    color: white !important;
}