// /* Extra small devices (portrait phones, less than 576px) */
@use '../../../assets/scss/elements/index.scss' as elements;

.card-dashboard-comfort {
  position: relative;
  top: -15px;
}

.tab-select {
  font-size: 62.5%;
  margin-top: 10px;
  margin-bottom: 15px;

  .tab-item {
    font-size: 1.8em;
    margin: 5px 10px;
    padding: 5px 5px 0 5px;
    cursor: pointer;
  }
  
  .tab-item-active {
    border-bottom: 3px solid var(--secondary);
  }
}

thead {
  font-size: 62.5%;

  th {
    font-size: 1.5em !important;
  }

  #empty-row {
    th:not(.table-col-group) {
      border: none;
    }

    .table-col-group {
      padding: 5px 0;
      border-left: 1px solid #dde2ef;
      border-right: 1px solid #dde2ef;
      border-bottom-width: 0;
    }
  }

  .start-col-group {
    border-left: 1px solid #dde2ef;
  }

  .end-col-group {
    border-right: 1px solid #dde2ef
  }

}

.pastille {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 5px;

  &.green {
    background-color: #548235;
  }

  &.yellow {
    background-color: #f0e407;
  }

  &.orange {
    background-color: #e88607;
  }

  &.red {
    background-color: #ff0101;
  }

  &.grey {
    /* A cercle with white cross inside inclined at 45°  */
    background-color: #b1b1b1;
    display: inline-block;
    border-radius: 50%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 60%; 
      background-color: #fff;
      transform: translate(-50%, -50%) rotate(45deg); 
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px; 
      height: 60%; 
      background-color: #fff;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.icon-sorting-menu {
  position: relative;
  top: -1px;
  font-size: 12px;
  margin-left: 3px;
}

.icon-sorting-item {
  margin-left: 10px;
  font-size: 20px;
}

.threshold-line {
  text-align: left;
  padding: 0;
  margin: 0;
  color: #b2afaf;
}


.sensor-analysis-filter-container {
  align-self: baseline;
}

.sensor-analysis-buttons {
  display: flex;
  justify-content: center;

  button {
    font-size: 0.65rem;
    margin: 0 5px;
  }
}
#period-filter{
  input[name='dates'] {
    font-family: Roboto, sans-serif;
    min-height: 29px;
    color: #b2afaf;
    font-size: 1.2em;
  }
}

.chart-container {
  height: 440px;
  margin-bottom: 95px;
}

.graph-title {
  font-weight: normal;
  font-size: 1.3rem;
  text-align: center;
}

.table-card-hover {
  display: none;
  position: absolute;
  width: max-content;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
  z-index: 2;
  text-align: left;

  p {
    margin-bottom: 5px;
  }
}

.table-toggle-button, #table-temp-button, #table-co2-button {
  min-width: 115px;
  svg {
    margin-right: 10px;
  }
}


.close-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  button {
    border: none;
    background-color: transparent;  
    .close-icon {
      color: #d53a42;
      font-size: 20px;
      margin: 0 10px;
    }
  }

}

#limit {
  max-width: 100px;
}

.analysis-slider-text {
  font-size: 1.3rem;
}

.slick-dots {
    position: relative;
    top: -15px;
}


.link-primary:hover {
  color: var(--primary) !important;
}


.page-link.primary:hover {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.page-link.secondary:hover {
	background-color: var(--secondary) !important;
	border-color: var(--secondary) !important;
}
